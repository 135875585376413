import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'
import ContentSection from '../components/ContentSection'
import LoanGrid from '../components/LoanGrid'

class Page extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const lenders = this.props.data.lenders.edges
    const siteAddress = 'https://www.bluemonday.se'

    return (
      <Layout location={this.props.location} title={page.frontmatter.title}>
        <ProgressiveBgImage
          hqImage="/images/office-desk.jpg"
          lqImage="/images/office-desk-lq.jpg"
        >
          <article className="landing-page">
            <HeaderMeta
              title={page.frontmatter.title}
              description={page.frontmatter.description}
              canonical={`${siteAddress}${page.fields.slug}`}
            />
            <section className="section content-box">
              <h1>{page.frontmatter.title}</h1>
            </section>
            <ContentSection
              markdown_content={page.frontmatter.intro_content_section}
              image={page.frontmatter.intro_content_section_image}
              image_alt={`${page.frontmatter.title} grafiikat`}
            />
            

            <ContentSection
              markdown_content={page.frontmatter.upper_content_section}
              image={page.frontmatter.upper_content_section_image}
              image_alt={page.frontmatter.upper_content_section_image_alt_text}
            />
            <section className="section">
            </section>
            <ContentSection
              markdown_content={page.html}
            />
            <section className="section">
            <LoanGrid
            items={lenders.map(({ node }) => {
              return {
                title: node.frontmatter.title || node.fields.slug,
                slug: node.fields.slug,
                image: node.frontmatter.logo,
                rating: node.frontmatter.rating,
                bullet_points: node.frontmatter.bullet_points,
                affiliate_link: node.frontmatter.affiliate_link,
                rating: node.frontmatter.rating,
                age_limit: node.frontmatter.age_limit,
                max_loan_amount: node.frontmatter.max_loan_amount,
                min_loan_amount: node.frontmatter.min_loan_amount,
                min_loan_period: node.frontmatter.min_loan_period,
                max_loan_period: node.frontmatter.max_loan_period,
                is_featured: node.frontmatter.is_featured,
                lender_type: node.frontmatter.lender_type
              }
            })}
          />
            </section>
          </article>
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
    }

    lenders: allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "lender" } } }
      sort: { fields: [frontmatter___rating], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            slug
            title
            description
            intro_text
            featured_image
            logo
            content_type
            is_high_interest_loan
            affiliate_link
            rating
            age_limit
            max_loan_amount
            min_loan_amount
            min_loan_period
            max_loan_period
            affiliate_link
            is_featured
            lender_type
          }
        }
      }
    }
  }
`
